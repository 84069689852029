import axios from "axios";

import {
    getLocalStorage
} from '../utilities/localstorage'

import {
    ContentsResponseType
} from '../types';

var BASE_URL = process.env.REACT_APP_TARGET_URL;

export const API = axios.create({
    
})

// get contents 
export const getContentsRequest =async () => {
    const url = BASE_URL + `/v1/content/recommend`;
    API.defaults.withCredentials = true;

    try{
        const access_token = getLocalStorage('access_token');
        if(access_token)
        {
            const response = await API.get(url, {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            })
            
            return response.data;
        }
        else
        {
            return { status: 401, data: {message: '로그인 만료', action: '/error'}};
        }
       
    }
    catch(err)
    {
        return { status: 401, data: {message: '로그인 만료', action: '/error'}};
    }
}