import styled from "styled-components";
import {
    MonthlyCalendarType
} from '../../../../types';
import { useEffect, useState } from "react";

import {
    getStoreMonthlyBookingListRequest
} from '../../../../apis/BookingApi';

const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    background-color: rgba(0,0,0,0.8);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
`;

const ButtonSection = styled.div`
    width: 100%;
    height: calc(100vh - 85%);
`;

const Section = styled.div`
    width: 100%;
    height: 85%;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background-color: #fff;
    overflow: hidden;
    color: #000000;
    z-index: 100;
`;

const CalendarBody = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
`;

const WeekSection = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

const WeekHeaderSection = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid #979797;
`;

const WeekHeader = styled.div<{color: string}>`
    width: calc(100% / 7);
    height: 45px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: ${(props) => props.color};
`;


const Cell = styled.div<{left: boolean, right: boolean}>`
    width: calc(100% / 7);
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 0.5px solid #979797;
    padding-left: 2px;
    border-right: ${(props) => props.right ? '0.1px solid #979797' : '0'};
    border-left: ${(props) => props.left ? '0.1px solid #979797' : '0'};
    overflow: hidden;
`;

const RowWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
`;
const Row100 = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;


const DateText = styled.span<{color: string}>`
    margin-top: 5px;
    font-size: 14px;
    margin-bottom: 7px;
    color:${(props) => props.color};
`;
const BookingText = styled.span<{color: string}>`
    font-size: 8px;
    margin-bottom: 3px;
    color:${(props) => props.color};
    white-space: nowrap; /* 텍스트가 줄 바꿈되지 않도록 설정 */
    overflow: hidden; /* 넘치는 텍스트 숨기기 */
    text-overflow: ellipsis; /* 넘친 텍스트에 "..." 추가 */
    width: 100%
`;

const CurDate = styled.div`
    font-size: 12px;
    background-color: #FF4A8B;
    color: #FFFFFF;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1px;
`;

const Row = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
`;

const Year = styled.div`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: -0.3px;
    color: #191F28;
`;

const Month = styled.div`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: -0.3px;
    color: #191F28;
`;

const BeforeButton = styled.img`
    cursor: pointer;
`;

const AfterButton = styled.img`
    margin-left: 35px;
    cursor: pointer;
`;


interface DetailCalendarProps {
    storeId: number;
    onSelect: (year: number, month: number, date: number) => void;
    onClose: () => void;
}

export function DetailCalendarModal({
    storeId,
    onClose,
    onSelect
}: DetailCalendarProps){

    const [ week, setWeek ] = useState<MonthlyCalendarType [][]>();
    const [ selectedYear, setSelectedYear ] = useState<number>(0);
    const [ selectedMonth, setSelectedMonth ] = useState<number>(0);
    const [ curYear, setCurYear] = useState<number>(0);
    const [ curMonth, setCurMonth] = useState<number>(0);
    const [ curDate, setCurDate] = useState<number>(0);

    function nextMonth()
    {
        if(selectedMonth === 12)
        {
            setSelectedMonth(1);
            setSelectedYear(selectedYear as number + 1);

        }
        else
        {
            setSelectedMonth(selectedMonth as number + 1);

        }
    }

    function preMonth()
    {
        if(selectedMonth === 1)
        {
            setSelectedMonth(12);
            setSelectedYear(selectedYear as number - 1);
        }
        else
        {
            setSelectedMonth(selectedMonth as number - 1);
        }
    }
    
    const getMonthlyBookingDataList = async (storeId: number, year: number, month: number) => {
        // storeId, date
        const res = await getStoreMonthlyBookingListRequest(storeId, year, month);
        if(res.status === 'success')
        {
            if(res.data === null)
            {
                
            }
            else
            {
                const data:MonthlyCalendarType[] = res.data;
                groupIntoWeeks(data);
            }
                    
        }
    }

    const groupIntoWeeks = (dates: MonthlyCalendarType[]) => {
        const weeks: MonthlyCalendarType[][] = [];
        let currentWeek: MonthlyCalendarType[] = [];
    
        dates.forEach((date, index) => {
          currentWeek.push(date);
          // A week has 7 days, push to weeks and reset currentWeek
          if ((index + 1) % 7 === 0) {
            weeks.push(currentWeek);
            currentWeek = [];
          }
        });
    
        // Push the remaining days if any
        if (currentWeek.length) {
          weeks.push(currentWeek);
        }
        setWeek(weeks);
    };
    
    // const weeks = groupIntoWeeks(data);

    useEffect(() => {
        const cur = new Date();
        setSelectedYear(cur.getFullYear());
        setSelectedMonth(cur.getMonth() + 1);
        setCurYear(cur.getFullYear());
        setCurMonth(cur.getMonth() + 1);
        setCurDate(cur.getDate());
    }, [])

    useEffect(() => {
        getMonthlyBookingDataList(storeId, selectedYear, selectedMonth);
    }, [storeId, selectedYear, selectedMonth])

    return (
        <Container>
            <ButtonSection 
                onClick={() => {
                    onClose();
                }}
            />
            <Section>
                <CalendarBody>
                    <RowWrapper>                    
                        <BeforeButton src={require('../../../../styles/assets/icon/expand_left_more.png')} 
                            onClick={preMonth}
                        />
                        <Row100>
                            <Year>{selectedYear}.</Year>
                            <Month>{selectedMonth}</Month> 
                        </Row100>
                        <AfterButton src={require('../../../../styles/assets/icon/expand_right_more.png')} 
                            onClick={nextMonth}
                        />                                                   
                    </RowWrapper>
                    <WeekHeaderSection>
                        <WeekHeader color={'red'}>일</WeekHeader>
                        <WeekHeader color={'#000'}>월</WeekHeader>
                        <WeekHeader color={'#000'}>화</WeekHeader>
                        <WeekHeader color={'#000'}>수</WeekHeader>
                        <WeekHeader color={'#000'}>목</WeekHeader>
                        <WeekHeader color={'#000'}>금</WeekHeader>
                        <WeekHeader color={'#000'}>토</WeekHeader>                        
                    </WeekHeaderSection>
                    {week && week.map((element, weekIndex) => (
                        <WeekSection key={weekIndex} className="calendar-week">
                            {element.map((dateData, dayIndex) => (
                                <Cell
                                    key={dayIndex}
                                    left={dayIndex % 7 !== 0}
                                    right={element.length - 1 === dayIndex}
                                    onClick={() => {
                                        // in month 아니면 다시 계산
                                        
                                        if(!dateData.inMonth)
                                        {
                                            if(selectedMonth === 1)
                                            {
                                                onSelect(selectedYear - 1, 12, dateData.date)
                                            }
                                            else
                                            {
                                                onSelect(selectedYear, selectedMonth - 1, dateData.date)
                                            }
                                        }
                                        else
                                        {
                                            onSelect(selectedYear, selectedMonth, dateData.date)
                                        }                                        
                                    }}
                                >
                                    {
                                        (curYear === selectedYear && curMonth === selectedMonth && curDate === dateData.date) ? <CurDate>{dateData.date}</CurDate> : <DateText color={dateData.inMonth ? '#000' : '#979797'} >{dateData.date}</DateText>
                                    }
                                    
                                    {dateData.menuName.length > 0 && (
                                        <Row>
                                            {dateData.menuName.map((menu, menuIndex) => (
                                                (menuIndex < 4 ? <BookingText color={'#000'} key={menuIndex}>{menu}</BookingText> : null)
                                            ))}
                                            
                                            {dateData.menuName.length > 4 ? <BookingText color={'#979797'}>그 외 {dateData.menuName.length - 4}개...</BookingText> : null}
                                            
                                        </Row>
                                    )}
                                </Cell>
                            ))}
                        </WeekSection>
                    ))} 
                </CalendarBody>
                
            </Section>
        </Container>
    );
}