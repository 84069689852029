import styled from "styled-components";

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    position: fixed;
    border-bottom: 14px solid #F2F2F2;
`;

const Bar = styled.div`
    width: 100%;
    height: 56px;
    background-color: #FFFFFF;
    /* background-color: #ECE8FE; */

    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5vw;
    z-index: 10;
`;

const Logo = styled.img`
    width: 120px;
`;

const SearchInput = styled.input`
    width: 100%;
    height: 48px;
    border-radius: 10px;
    border: 1px solid #AFB8C0;
    padding-left: 10px;
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 15px;
`;

const Wrapper = styled.div`
    width: 100%;
    position: relative;
    padding: 5vw;
`;

const SearchButton = styled.img`
    position: absolute;
    top: 35px;
    right: 35px;
    width: 20px;
    z-index: 10;
    cursor: pointer;
`;

interface HeaderProps {
    onAdminButton: () => void;
}

export function Header ({
    onAdminButton
}: HeaderProps) {
    return (
        <Container>
            <Bar>
                <Logo src={require('../../../styles/assets/logo_new.png')} />
            </Bar>
            <Wrapper>
                <SearchInput 
                    placeholder="이름, 키워드로 검색"
                />
                <SearchButton 
                    src={require('../../../styles/assets/icon/search.png')}
                />
            </Wrapper>
        </Container>
    );
};
