import styled from "styled-components";

const Container = styled.div`
    width: 100%;
    min-height: 100vh;
`;

function Setting(){
    return (
        <Container>
            
        </Container>
    );
}

export default Setting;