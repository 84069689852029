// schedule
// menu
// etc info
import styled from "styled-components";
import Header from "./Header";

import { 
    useEffect, 
    useState 
} from "react";

import {
    storeState,
    scheduleState,
} from '../../../stores'

import {
    getScheduleListRequest
} from '../../../apis/BookingApi';

import { 
    useRecoilState
} from "recoil";

import {
    StoreSetting,
    ScheduleSetting,
    BlockDaySetting,
    MenuSetting,
    EtcInfoSetting,
    PlanSetting,
    PhotoUploadSetting,
    AdvertisementSetting,
    CommunityMenu
} from '../../../components/organisms';

import {
    Gap
} from '../../../components/atoms'

const Container = styled.div`
    width: 100%;
    overflow: auto;
    min-height: 100vh;
`;

const Line = styled.div`
    height: 8px;
    width: 100%;
    background: #F2F2F2;
`;

interface SettingProps {
    onStoreUpdate: () => void;
}

function Setting({
    onStoreUpdate
}: SettingProps){

    const [ store, setStore ] = useRecoilState(storeState);
    const [ schedule, setSchedule ] = useRecoilState(scheduleState);

    const [ loading, setLoading ] = useState(true);

    const getScheduleList = async (storeId: number) => {
        const dowScheduleResponse = await getScheduleListRequest(storeId);
        if(dowScheduleResponse.status === 'success')
        {
            setSchedule(dowScheduleResponse.data);
        }
    }

    const getSettingInfo = async (storeId: number) => {
        setLoading(true);
        const dowScheduleResponse = await getScheduleListRequest(storeId);        

        if(dowScheduleResponse.status === 'success')
        {
            setSchedule(dowScheduleResponse.data);
        }
        
        setLoading(false);
    }

    useEffect(() => {
        document.body.style.overflowY = 'auto';
        getSettingInfo(store.id);
    }, [])

    return (
        <Container className="request">
            <Header />
            <div style={{height: '96px'}}></div>
            {
                loading ? null : (
                    <>
                        <StoreSetting 
                            storeInfo={store}
                            onUpdate={onStoreUpdate}
                        />
                        <Line />
                        <ScheduleSetting
                            dowScheduleList={schedule}
                            onChange={() => {
                                getScheduleList(store.id);
                            }}
                        />
                        <Line />
                        <BlockDaySetting
                            storeId={store.id}
                        />
                        <Line />
                        <MenuSetting 
                            storeId={store.id}
                        />
                        <Line />
                        <EtcInfoSetting 
                            storeId={store.id}
                        />
                        <Line />
                        <PhotoUploadSetting 
                            storeId={store.id}
                        />
                        <Line />
                        <PlanSetting 
                            storeId={store.id}
                        />    
                        <Line />      
                        <AdvertisementSetting 
                            storeId={store.id}
                        />     
                        <Line />
                        <CommunityMenu />    
                    </>
                )
            }
            <div style={{height: '10px'}}></div>
        </Container>
    );
}

export default Setting;