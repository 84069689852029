import { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import styled from "styled-components";
import qs from 'qs';
import Const from '../../constant';
import { 
    NavBarCm
 } from "../../components/molecules";

import { 
    useRecoilState
 } from "recoil";

 import {
    storeState
} from '../../stores';

import {
    getStoreRequest
} from '../../apis/BookingApi';

import {
    getLocalStorage
} from '../../utilities/localstorage'

import { 
    useLocation, useParams
} from "react-router-dom";

import Add from "./add";
import List from "./list";
import Setting from "./setting";

const Container = styled.div`
    overflow: hidden;
`;

function Community(){

    const [ view, setView ] = useState(0);
    const [ loading, setLoading ] = useState(true);
    const location = useLocation();
    const [ store, setStore ] = useRecoilState(storeState);

    const query = qs.parse(location.search, {
        ignoreQueryPrefix: true
    });

    const getStore = async () => {
        const res = await getStoreRequest();
        if(res.status === '200')
        {
            setStore(res.data);
            setLoading(false)
        }
        else
        {
            // alert("미가입 유저")
            window.open("https://friday11am.com")
        }
    }

    useEffect(() => {
        const access_token = getLocalStorage('access_token');

        if(access_token)
        {
            //get store info 
            //no auth
            //kakao_login
            getStore();
        }
        else
        {            
            const path = `${location.pathname}`;       
            window.location.href = `https://kauth.kakao.com/oauth/authorize?client_id=${Const.REST_API_KEY}&redirect_uri=${Const.REDIRECT_URI}&response_type=code&prompt=login&state=${path}`;
        }

        const navigation = query.navigation;
        if(navigation !== undefined && navigation !== null)
        {
            setView(Number(navigation));
        }
  
    }, [])

    return (
        <Container>
            {loading ? <></> :
            <>
                {
                    view === 0 ? (
                        
                        store && 
                        <List />                        
                    ) 
                    :
                    view === 1 ? (
                        <Add />
                    )
                    :
                    (
                        <Setting />
                    )
                }
                <div style={{height: '70px'}}></div>
                <NavBarCm 
                    active={view}
                    onClick={(path) => {
                        setView(path)
                    }}
                />
            </>
            }
        </Container>
        // <div>
        //     <ReactQuill 
        //         style={{ width: "800px", height: "600px" }}
        //         onChange={setContent}
        //     />
        // </div>
        // 관리자로 돌아가기 
    );
}

export default Community;