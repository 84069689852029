import styled from "styled-components";
import {
    Header
} from '../../../components/molecules';

import {
    getContentsRequest
} from '../../../apis/CommunityApi';
import { useEffect, useState } from "react";
import { ContentsResponseType } from "../../../types";

const Container = styled.div`
    width: 100%;
    min-height: 100vh;
`;

function List(){

    const [list, setList] =useState<ContentsResponseType []>([]);

    const getContents = async () => {
        const res = await getContentsRequest();

        if(res.status === '200')
        {
            console.log('info' + JSON.stringify(res));
            setList([...res.data])
        }
        else
        {
            //go login
            // accesstoken 삭제

        }
    }

    useEffect(() => {
        getContents()
    }, [])

    return (
        <Container>
            <Header 
                onAdminButton={()=> {
                    
                }}
            />
            검색 
            리스트
        </Container>
    );
}

export default List;