// header 
// schedule table
// Request 
// modal
// recoil booking list 
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';

import Header from './Header';

import {
    MonthlyCalendarType,
    BookingAdminType,
    BookingType
} from '../../../types'

import {
    Calendar,
    UserPaymentInfoModal,
    DetailCalendarModal
} from '../../../components/modals';

import {
    AdminSchedule,
    // ScheduleTable,
    // RequestList
} from '../../../components/organisms'

import { 
    storeState 
} from '../../../stores';

import {
    getStoreMonthlyBookingListRequest,
    getAdminStoreBookingInfoRequest
} from '../../../apis/BookingApi';

import { 
    leadingZeros 
} from '../../../utilities/convert';

import {
    BlockDayElement
} from '../../../components/atoms';

const Container = styled.div`
    width: 100%;
    overflow: hidden;
`;

function Today(){
    const [ calendarView, setCalendarView ] = useState(false);
    const [ bookingModal, setBookingModal ] = useState(false);
    const [ selectedYear, setSelectedYear ] = useState<number>(0);
    const [ selectedMonth, setSelectedMonth ] = useState<number>(0);
    const [ selectedDate, setSelectedDate ] = useState<number>(0);
    const [ loading, setLoading ] = useState(false);
    const [ bookingInfo, setBookingInfo ] = useState<BookingAdminType>();
    const store = useRecoilValue(storeState);
    const [ selectedBookingData, setSelectedBookingData ] = useState<BookingType>();

    const [ selectedBookingId, setSelectedBookingId ] = useState(0);
    const [ selectedModalType, setSelectedModalType ] = useState(1); // 1, 2
    const [ isBlockDay, setIsBlockDay ] = useState<boolean>(false);

    const selectBookingData = (bookingId: number) => {
        (bookingInfo as BookingAdminType).confirmedBookingList.map((booking) => {
            if(booking.bookingId === bookingId)
            {
                setSelectedBookingData(booking);
            }
        })
    }

    const getBookingInfo = async (year:number, month:number, date:number) => {
        setLoading(true);
        // storeId, date
        const selectedDate = year + "-" + leadingZeros(month.toString(), 2) + "-" + leadingZeros(date.toString(), 2);
        const res = await getAdminStoreBookingInfoRequest(store.id, selectedDate);
        if(res.status === 'success')
        {
            if(res.data === null)
            {
                setIsBlockDay(true);
            }
            else
            {
                setIsBlockDay(false);
                const data:BookingAdminType = res.data;
                setBookingInfo(data); 
            }
                    
        }
        setLoading(false)
    }

    useEffect(() => {
        document.body.style.overflowY = 'hidden';
        const cur = new Date();
        setSelectedYear(cur.getFullYear());
        setSelectedMonth(cur.getMonth());
        setSelectedDate(cur.getDate());
        // 주기적 호출
        getBookingInfo(cur.getFullYear(), cur.getMonth() + 1, cur.getDate());
        // load complete booking, request list
        // skeleton
    }, [])

    return (
        <Container>            
            {
                selectedBookingId > 0 && 
                <UserPaymentInfoModal 
                    selectedBookingId={selectedBookingId}
                    type={selectedModalType}
                    onClose={() => {
                        setSelectedBookingId(0);
                        getBookingInfo(selectedYear, selectedMonth + 1, selectedDate);
                    }}
                />
            }
            {/* {
                calendarView && <Calendar 
                    mode={1}
                    year={selectedYear}
                    month={selectedMonth}
                    date={selectedDate}
                    onClose={() => {
                        setCalendarView(false)
                    }}
                    onSelect={
                        (year, month, date) => {
                            setCalendarView(false)
                            // 달력 이미지 변경, 조회 \
                            // console.log(year + "-" + month + "-" + date)
                            setSelectedYear(year);
                            setSelectedMonth(month);
                            setSelectedDate(date);
                            getBookingInfo(year, month + 1, date);
                        }
                    }
                />
            } */}
            {
                calendarView && <DetailCalendarModal
                    storeId={store.id}
                    onClose={() => {
                        setCalendarView(false);
                    }}
                    onSelect={
                        (year, month, date) => {
                            setCalendarView(false)
                            // 달력 이미지 변경, 조회 \
                            setSelectedYear(year);
                            setSelectedMonth(month - 1);
                            setSelectedDate(date);
                            getBookingInfo(year, month, date);
                        }
                    }
                />
            }
            <Header 
                year={selectedYear}
                month={selectedMonth + 1}
                date={selectedDate}
                onCalender={() => {
                    setCalendarView(true);
                }}
            />
            <div style={{height: '56px'}}></div>
            {
                isBlockDay ? (
                    <BlockDayElement />
                ) : (                    
                    !loading && bookingInfo &&
                    <AdminSchedule 
                        requestDate={selectedYear + "-" + leadingZeros((selectedMonth + 1).toString(), 2) + "-" + leadingZeros(selectedDate.toString(), 2)}
                        open={bookingInfo.open}
                        close={bookingInfo.close}
                        bookingList={bookingInfo.confirmedBookingList}
                        breakTimeList={bookingInfo.breakTimeList}
                        onSelectBooking={(bookingId) => {      
                            setSelectedBookingId(bookingId);           
                        }}
                        onReload={() => {
                            setSelectedBookingId(0);
                            getBookingInfo(selectedYear, selectedMonth + 1, selectedDate);
                        }}
                    />                    
                )
            }
            {/* <RequestList 
                storeId={store.id}
                onChange={() => {
                    getBookingInfo(selectedYear, selectedMonth + 1, selectedDate);
                }}
            /> */}
        </Container>
    );
}

export default Today;