// admin today의 스케줄 구성 organism

import { useEffect, useState } from "react";
import styled from "styled-components";

import {
    BookingType,
    BreakTimeType,
    BookingBlockType
} from '../../../../types'

import {
    leadingZeros
} from '../../../../utilities/convert';

import {
    AddBookingModal,
    AddDailyBreakTimeModal
} from '../../../modals';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    padding-left: 5vw;
    padding-right: 5vw;
    padding-bottom: 150px;
`;

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const ColWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 12px;
    cursor: pointer;
    margin-left: 15px;
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Icon = styled.img`
    width: 16px;
`;
const Icon2 = styled.img`
    width: 20px;
    margin-top: -5px;
`;

const Label = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 500;
    font-size: 7px;
    line-height: 18px;
    letter-spacing: -0.25px;
    color: #000000;
`;

const Title = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: -0.25px;
    color: #000000;
    margin-top: 40px;
    margin-bottom: 30px;
`;

const Table = styled.div`
    display: flex;
    width: 100%;
    overflow-y: auto;
    padding-top: 20px;
`;

const TimeArea = styled.div`
    width: 15%;
`;

const Time = styled.div`
    height: 55px;   
    width: 100%;
    position: relative;
`;

const TimeLabel = styled.span`
    position: absolute;
    top: -4px;
    right: 11px;
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: -0.375px;
    color: #525558;
`;

const BlockArea = styled.div`
    width: 85%;
    position: relative;
`;

const TimeDistribute = styled.div`
    height: 55px;   
    width: 100%;
    border-top: 1px solid #CEDDE2;
`;  

const Block = styled.div<{top: number, height: number, background: string}>`
    position: absolute;
    width: 100%;
    height: ${(props) => props.height}px;
    top: ${(props) => props.top}px;
    left: 0;
    color: #FFFFFF;
    background: ${(props) => props.background};
    mix-blend-mode: normal;
    opacity: 0.75;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    letter-spacing: -0.15px;
    color: #FFFFFF;
    white-space: pre-line;
    word-wrap:break-all;
`; 

interface AdminScheduleProps{
    open: string;
    close: string;
    bookingList: BookingType[];
    breakTimeList: BreakTimeType [];
    onSelectBooking: (selectedIndex: number) => void;
    onReload: () => void;
    requestDate: string;
}

export function AdminSchedule({
    open,
    close,
    bookingList,
    breakTimeList,
    onSelectBooking,
    onReload,
    requestDate
}: AdminScheduleProps){
    // 계산, break, booking 표시
    const [ operationTime, setOperationTimes ] = useState<string[]>([]);
    const [ bookingBlockList, setBookingBlockList ] = useState<BookingBlockType []>([]);
    const [ breakBlockList, setBreakBlockList ] = useState<BookingBlockType []>([]);
    const [ addBookingModalView, setAddBookingModalView ] = useState(false);

    const [ addDailyBreakTimeModalView, setAddDailyBreakTimeModalView ] = useState(false);

    function cutMemo(memo: string){
        const memos = memo.split("\n");
        if(memo === '')
            return '';

        if(memos.length > 1)
            return memos[0]+"...";
        else
            return memo;
    }

    function setOperationTime(open: string, close: string){
        // operation time list 
        let startHour = Number(open.slice(0, 2))
        let endHour = Number(close.slice(0, 2))
        let startMinute = Number(open.slice(3, 5))        
        let operation: string[] = [];
        for(var i = startHour; i <= endHour; i++)
        {
            let dt = "AM"
            if(startHour >= 12)
            {
                dt = "PM"
            }
            let time = leadingZeros(i.toString(), 2) + ":"+ leadingZeros(startMinute.toString(), 2);
            operation.push(time);
        }

        setOperationTimes([]);
        setOperationTimes(operation);

        setBookingBlockList([]);
        setBookingBlock();
        setBreakBlockList([]);
        setBreakBlock();
    }

    const setBookingBlock = () =>{
        // 
        let list: BookingBlockType[] = [];
        for(let i = 0 ;i < bookingList.length; i++)
        {
            // start position 
            // (start time - opentime) => min * 9.166666666667 
            // duration 
            // height 10분당 9.166666666 
            // background color
            
            let startTime = new Date(bookingList[i].time);
            let startMinute = startTime.getHours()* 60 + startTime.getMinutes();
            
            let openMinute = Number(open.slice(0,2)) * 60 + Number(open.slice(3, 5));

            let startPosition = 0;
            if(startMinute >= openMinute)
            {
                startPosition = (startMinute - openMinute) / 10 * 9.166666666667
            }
          
            let height = 0;
            

            let block: BookingBlockType = {
                startPosition: startPosition,
                height: bookingList[i].duration / 10 * 9.166666666667,
                background: i % 2 == 0 ? '#FF4A8B' : '#7D93A6',
                bookingId: bookingList[i].bookingId,
                serviceName: bookingList[i].menuName,
                userMemo: bookingList[i].userMemo
            };

            list.push(block);
        }

        setBookingBlockList(list);
    }

    function setBreakBlock(){
        //
        let list: BookingBlockType[] = [];
        for(let i = 0 ;i < breakTimeList.length; i++)
        {
            // start position 
            // (start time - opentime) => min * 9.166666666667 
            // duration 
            // height 10분당 9.166666666 
            // background color
            let startMinute = Number(breakTimeList[i].start.slice(0,2)) * 60 + Number(breakTimeList[i].start.slice(3, 5));            
            let openMinute = Number(open.slice(0,2)) * 60 + Number(open.slice(3, 5));

            let startPosition = 0;
            let diffMin = 0;
            if(startMinute >= openMinute)
            {
                startPosition = (startMinute - openMinute) / 10 * 9.166666666667
            }
            else
            {
                // 버린 시간만큼 빼기
                diffMin = openMinute - startMinute;
                
            }


            let block: BookingBlockType = {
                startPosition: startPosition,
                height: (breakTimeList[i].duration -diffMin) / 10 * 9.166666666667,
                background: '#9C27B0',
                bookingId: 0,
                serviceName: `휴식${breakTimeList[i].memo ? `(${breakTimeList[i].memo})` : ''} `,
                userMemo: ''
            };

            list.push(block);
        }

        setBreakBlockList(list);
    }


    useEffect(() => {
        setOperationTime(open, close);
    }, [])
    
    return (
        <Container>
            {
                addBookingModalView &&
                <AddBookingModal 
                    requestDate={requestDate}
                    onClose={() => {
                        setAddBookingModalView(false);
                        onReload();
                    }}
                />
            }
            {
                addDailyBreakTimeModalView &&
                <AddDailyBreakTimeModal 
                    requestDate={requestDate}
                    onClose={() => {
                        setAddDailyBreakTimeModalView(false);
                        onReload();
                    }}
                />
            }
            <Wrapper>
                <Title>오늘의 스케줄</Title>
                <ButtonWrapper>
                    <ColWrapper
                        onClick={() => {
                            setAddBookingModalView(true);
                        }}
                    >
                        <Icon src={require('../../../../styles/assets/icon/add.png')} />
                        <Label>예약 추가히기</Label>
                    </ColWrapper>

                    <ColWrapper
                        onClick={() => {
                            setAddDailyBreakTimeModalView(true)
                        }}
                    >
                        <Icon2 src={require('../../../../styles/assets/icon/break.png')} />
                        <Label>휴식설정</Label>
                    </ColWrapper>
                    
                </ButtonWrapper>
                
            </Wrapper>            
            <Table> 
                <TimeArea>
                    {
                        operationTime.map((time, index) =>{
                            return(
                                <Time 
                                    key={index}>
                                    <TimeLabel>{time}</TimeLabel>
                                </Time>
                            );
                        })
                    }
                </TimeArea>
                <BlockArea>
                    {
                        //operation line
                        operationTime.map((time, index) =>{
                            return(
                                <TimeDistribute
                                    key={index}                                   
                                />
                            );
                        })
                    }
                    {
                        // booking block 
                        bookingBlockList.map((block, index) => {
                            return(
                                <Block
                                    key={index}
                                    top={block.startPosition}
                                    height={block.height}
                                    background={block.background}
                                    onClick={()=>{
                                        onSelectBooking(block.bookingId);
                                    }}
                                >   
                                    {block.serviceName}<br/>
                                    {cutMemo(block.userMemo)}
                                </Block>
                            );
                        })
                    }
                    {
                        // break block
                        breakBlockList.map((block, index) => {
                            return(
                                <Block
                                    key={index}
                                    top={block.startPosition}
                                    height={block.height}
                                    background={block.background}
                                    onClick={()=>{
                                        onSelectBooking(block.bookingId);
                                    }}
                                >   
                                    {block.serviceName}
                                </Block>
                            );
                        })
                    }
                </BlockArea>
            </Table>
        </Container>
    );
}