import axios from "axios";

import {
    getLocalStorage
} from '../utilities/localstorage'

import {
    DowScheduleType,
    BreakTimeType,
    StoreType,
    BlockDayType, 
    MenuType,
    EtcInformationType,
    BookingRequestType,
    PaymentType,
    UpdateCustomerType,
    CustomerType,
    AddBookingType,
    UpdateBookingType,
    StorePhotoUploadType,
    DailyBreakTimeType
} from '../types';

var BASE_URL = process.env.REACT_APP_TARGET_URL;

export const API = axios.create({
    
})

// api list 

// list selector 
// post recoil update
// 

export const verifyTokenRequest = async (token: string) => {
    const url = BASE_URL + `/v1/sign/verify`;
    API.defaults.withCredentials = true;

    try{
        const data = {
            token: token
        }
        const response = await API.post(url, data);

        return response.data.data;        
    }
    catch(err)
    {
        return 401;
    }
}

export const createUserAndStoreRequest = async (token: string, storeName: string, name: string, phone: string, roadAddress: string, jibunAddress: string, detailAddress: string, zoneCode: string, representativeNumber: string) => {
    const url = BASE_URL + `/v1/sign/partner`;
    API.defaults.withCredentials = true;

    try{
        const data = {
            kakaoToken: token,
            realName: name,
            storeName: storeName,
            phone: phone,
            roadAddress: roadAddress,
            jibunAddress: jibunAddress,
            detailAddress: detailAddress,
            zoneCode: zoneCode,
            representativeNumber: representativeNumber
        }

        const response = await API.post(url, data);

        return response.data;    
    }
    catch(err)
    {
        return { status: 400, data: {message: '잘못된 정보입니다.', action: '/error'}};
    }
}

export const getStoreRequest = async () => {
    const url = BASE_URL + `/v1/store`;
    API.defaults.withCredentials = true;

    try{
        const access_token = getLocalStorage('access_token');
        if(access_token)
        {
            const response = await API.get(url, {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            })
            
            return response.data;
        }
        else
        {
            return { status: 401, data: {message: '로그인 만료', action: '/error'}};
        }
       
    }
    catch(err)
    {
        return { status: 401, data: {message: '로그인 만료', action: '/error'}};
    }
}

export const getInfluencerRequest =async () => {
    const url = BASE_URL + `/v1/influencer`;
    API.defaults.withCredentials = true;

    try{
        const access_token = getLocalStorage('in_access_token');
        if(access_token)
        {
            const response = await API.get(url, {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            })
            
            return response.data;
        }
        else
        {
            return { status: 401, data: {message: '로그인 만료', action: '/error'}};
        }
       
    }
    catch(err)
    {
        return { status: 401, data: {message: '로그인 만료', action: '/error'}};
    }
}

export const uploadBannerFileRequest = async (file:any, storeId: number, orderNo: number, type: string) => {
    const url = BASE_URL + `/v1/store/banner/${storeId}/${orderNo}/${type}`;

    try{
        const access_token = getLocalStorage('access_token');
        if(access_token)
        {
            const formData = new FormData();
            formData.append('file', file[0]);
    
            const response = await API.post(url, formData, {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            });
            return response.data;
        }
        else
        {
            return { status: 400, data: {message: '잘못된 정보입니다.', action: '/error'}};
        }
           
    }
    catch(err)
    {

        return { status: 400, data: {message: '잘못된 정보입니다.', action: '/error'}};
    }
}

export const getScheduleListRequest = async (storeId: number) => {
    const url = BASE_URL + `/v1/schedule/${storeId}`;
    API.defaults.withCredentials = true;

    try{
        const access_token = getLocalStorage('access_token');
        if(access_token)
        {
            const response = await API.get(url, {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            })
            
            return response.data;
        }
        else
        {
            // 재로그인
            return { status: 401, data: {message: '로그인 만료', action: '/error'}};
        }
       
    }
    catch(err)
    {
        return { status: 401, data: {message: '로그인 만료', action: '/error'}};
    }
}

export const updateScheduleRequest = async (dowSchedule:DowScheduleType) => {
    // dowScheduleList update
    const url = BASE_URL + `/v1/schedule`;
    API.defaults.withCredentials = true;

    try {
        const access_token = getLocalStorage('access_token');
        if(access_token)
        {
            const data = {
                ...dowSchedule
            }

            const response = await API.post(url, data, {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            })

            return response.data;
        }
        else
        {
            // 재로그인
            return { status: 401, data: {message: '로그인 만료', action: '/error'}};
        }
    }
    catch(err)
    {
        return { status: 401, data: {message: '로그인 만료', action: '/error'}};
    }
}

export const dupCheckStoreUrlNameRequest =async (storeUrl: string, storeId: number) => {
    const url = BASE_URL + `/v1/store/url/check/${storeUrl}/${storeId}`;
    API.defaults.withCredentials = true;

    try {
        const access_token = getLocalStorage('access_token');
        if(access_token)
        {
            const response = await API.get(url, {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            })

            return response.data;
        }
        else
        {
            // 재로그인
            return { status: 401, data: {message: '로그인 만료', action: '/error'}};
        }
    }
    catch(err)
    {
        return { status: 401, data: {message: '로그인 만료', action: '/error'}};
    }
}

export const updateStoreInfoRequest = async (storeInfo: StoreType) => {
    const url = BASE_URL + `/v1/store`;
    API.defaults.withCredentials = true;

    try {
        const access_token = getLocalStorage('access_token');
        if(access_token)
        {
            const data = {
                ...storeInfo
            }

            const response = await API.put(url, data, {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            })

            return response.data;
        }
        else
        {
            // 재로그인
            return { status: 401, data: {message: '로그인 만료', action: '/error'}};
        }
    }
    catch(err)
    {
        return { status: 401, data: {message: '로그인 만료', action: '/error'}};
    }
}

export const deleteScheduleRequest = async (dowScheduleId: number) => {
    
}

export const getBreakTimeListRequest = async (dowScheduleId: number) => {
    const url = BASE_URL + `/v1/schedule/break/${dowScheduleId}`;
    API.defaults.withCredentials = true;

    try{
        const access_token = getLocalStorage('access_token');
        if(access_token)
        {
            const response = await API.get(url, {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            })
            
            return response.data;
        }
        else
        {
            // 재로그인
            return { status: 401, data: {message: '로그인 만료', action: '/error'}};
        }
       
    }
    catch(err)
    {
        return { status: 401, data: {message: '로그인 만료', action: '/error'}};
    }
}

export const updateBreakTimeRequest = async (breakTimeList: BreakTimeType[], dowScheduleId: number) => {
    // dowScheduleList update
    const url = BASE_URL + `/v1/schedule/break/${dowScheduleId}`;
    API.defaults.withCredentials = true;

    try {
        const access_token = getLocalStorage('access_token');
        if(access_token)
        {
            const data = [
                ...breakTimeList
            ]

            const response = await API.post(url, data, {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            })

            return response.data;
        }
        else
        {
            // 재로그인
            return { status: 401, data: {message: '로그인 만료', action: '/error'}};
        }
    }
    catch(err)
    {
        return { status: 401, data: {message: '로그인 만료', action: '/error'}};
    }
}

export const deleteBreakTimeRequest = async (breakTimeId: number) => {
    
}

export const getBlockDayListRequest = async (storeId: number) => {
    const url = BASE_URL + `/v1/schedule/block/${storeId}`;
    API.defaults.withCredentials = true;

    try{
        const access_token = getLocalStorage('access_token');
        if(access_token)
        {
            const response = await API.get(url, {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            })
            
            return response.data;
        }
        else
        {
            // 재로그인
            return { status: 401, data: {message: '로그인 만료', action: '/error'}};
        }
       
    }
    catch(err)
    {
        return { status: 401, data: {message: '로그인 만료', action: '/error'}};
    }
}

export const updateBlockDayRequest = async (blocDay: BlockDayType) => {
    const url = BASE_URL + `/v1/schedule/block`;
    API.defaults.withCredentials = true;

    try {
        const access_token = getLocalStorage('access_token');
        if(access_token)
        {
            const data = {
                ...blocDay
            }

            const response = await API.post(url, data, {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            })

            return response.data;
        }
        else
        {
            // 재로그인
            return { status: 401, data: {message: '로그인 만료', action: '/error'}};
        }
    }
    catch(err)
    {
        return { status: 401, data: {message: '로그인 만료', action: '/error'}};
    }
}

export const deleteBlockDayRequest = async (blockDayId: number) => {
    const url = BASE_URL + `/v1/schedule/block/${blockDayId}`;
    API.defaults.withCredentials = true;

    try {
        const access_token = getLocalStorage('access_token');
        if(access_token)
        {
             const response = await API.delete(url, {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            })

            return response.data;
        }
        else
        {
            // 재로그인
            return { status: 401, data: {message: '로그인 만료', action: '/error'}};
        }
    }
    catch(err)
    {
        return { status: 401, data: {message: '로그인 만료', action: '/error'}};
    }
}

export const getMenuListRequest = async (storeId: number) => {
    const url = BASE_URL + `/v1/menu/${storeId}`;
    API.defaults.withCredentials = true;

    try{
        const access_token = getLocalStorage('access_token');
        if(access_token)
        {
            const response = await API.get(url, {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            })
            
            return response.data;
        }
        else
        {
            // 재로그인
            return { status: 401, data: {message: '로그인 만료', action: '/error'}};
        }
       
    }
    catch(err)
    {
        return { status: 401, data: {message: '로그인 만료', action: '/error'}};
    }  
}

export const updateMenuRequest = async (menu: MenuType) => {
    const url = BASE_URL + `/v1/menu`;
    API.defaults.withCredentials = true;

    try {
        const access_token = getLocalStorage('access_token');
        if(access_token)
        {
            const data = {
                ...menu
            }

            const response = await API.post(url, data, {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            })

            return response.data;
        }
        else
        {
            // 재로그인
            return { status: 401, data: {message: '로그인 만료', action: '/error'}};
        }
    }
    catch(err)
    {
        return { status: 401, data: {message: '로그인 만료', action: '/error'}};
    }
}

export const deleteMenuRequest = async (menuId: number) => {
    const url = BASE_URL + `/v1/menu/${menuId}`;
    API.defaults.withCredentials = true;

    try {
        const access_token = getLocalStorage('access_token');
        if(access_token)
        {
             const response = await API.delete(url, {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            })

            return response.data;
        }
        else
        {
            // 재로그인
            return { status: 401, data: {message: '로그인 만료', action: '/error'}};
        }
    }
    catch(err)
    {
        return { status: 401, data: {message: '로그인 만료', action: '/error'}};
    }
}

export const getEtcInfoListRequest = async (storeId: number) => {
    const url = BASE_URL + `/v1/info/${storeId}`;
    API.defaults.withCredentials = true;

    try{
        const access_token = getLocalStorage('access_token');
        if(access_token)
        {
            const response = await API.get(url, {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            })
            
            return response.data;
        }
        else
        {
            // 재로그인
            return { status: 401, data: {message: '로그인 만료', action: '/error'}};
        }
       
    }
    catch(err)
    {
        return { status: 401, data: {message: '로그인 만료', action: '/error'}};
    } 
}

export const updateEtcInfoRequest = async (etcInfo: EtcInformationType) => {
    const url = BASE_URL + `/v1/info`;
    API.defaults.withCredentials = true;

    try {
        const access_token = getLocalStorage('access_token');
        if(access_token)
        {
            const data = {
                ...etcInfo
            }

            const response = await API.post(url, data, {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            })

            return response.data;
        }
        else
        {
            // 재로그인
            return { status: 401, data: {message: '로그인 만료', action: '/error'}};
        }
    }
    catch(err)
    {
        return { status: 401, data: {message: '로그인 만료', action: '/error'}};
    }
}

export const deleteEtcInfoRequest = async (etcInfoId: number) => {
    const url = BASE_URL + `/v1/info/${etcInfoId}`;
    API.defaults.withCredentials = true;

    try {
        const access_token = getLocalStorage('access_token');
        if(access_token)
        {
             const response = await API.delete(url, {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            })

            return response.data;
        }
        else
        {
            // 재로그인
            return { status: 401, data: {message: '로그인 만료', action: '/error'}};
        }
    }
    catch(err)
    {
        return { status: 401, data: {message: '로그인 만료', action: '/error'}};
    }
}

// get store info
// 

export const getStoreBookingInfoRequest = async (storeUrl: string, date: string) => {
    const url = BASE_URL + `/v1/store/info/${storeUrl}/${date}`;
    API.defaults.withCredentials = true;

    try {
        const response = await API.get(url);
        return response.data;
    }
    catch(err)
    {
        return { status: 401, data: {message: '로그인 만료', action: '/error'}};
    }
}

// get advertisement info 
export const getAdvertisementInfoRequest =async (link: string) => {
    const url = BASE_URL + `/v1/advertisement/info/${link}`;
    API.defaults.withCredentials = true;

    try {
        const response = await API.get(url);
        return response.data;
    }
    catch(err)
    {
        return { status: 401, data: {message: '로그인 만료', action: '/error'}};
    }
}


// get store booking info
export const getAdminStoreBookingInfoRequest = async (storeId: number, date: string) => {
    const url = BASE_URL + `/v1/admin/booking/${storeId}/${date}`;
    API.defaults.withCredentials = true;

    try{
        const access_token = getLocalStorage('access_token');
        if(access_token)
        {
            const response = await API.get(url, {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            })
            
            return response.data;
        }
        else
        {
            // 재로그인
            return { status: 401, data: {message: '로그인 만료', action: '/error'}};
        }
       
    }
    catch(err)
    {
        return { status: 401, data: {message: '로그인 만료', action: '/error'}};
    } 
}


export const uploadMenuImageFileRequest = async (file:any, menuId: number, type: string) => {
    const url = BASE_URL + `/v1/menu/image/${menuId}/${type}`;

    try{
        const access_token = getLocalStorage('access_token');
        if(access_token)
        {
            const formData = new FormData();
            formData.append('file', file[0]);
    
            const response = await API.post(url, formData, {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            });
            return response.data;
        }
        else
        {
            return { status: 400, data: {message: '잘못된 정보입니다.', action: '/error'}};
        }
           
    }
    catch(err)
    {

        return { status: 400, data: {message: '잘못된 정보입니다.', action: '/error'}};
    }
}

// booking request
export const addBookingRequest = async(bookingRequest: BookingRequestType) => {
    const url = BASE_URL + `/v1/booking`;
    
    API.defaults.withCredentials = true;

    try {
        
        const data = {
            ...bookingRequest
        }

        const response = await API.post(url, data)

        return response.data;
        
    }
    catch(err)
    {
        return { status: 401, data: {message: '로그인 만료', action: '/error'}};
    }

}

export const getWaitBookingPageRequest = async (storeId: number, pNo: number, size: number, sort: string, state: number) => {
    const url = BASE_URL + `/v1/booking/wait/${storeId}/${pNo}/${size}/${sort}/${state}`;
    API.defaults.withCredentials = true;


    try {
        const access_token = getLocalStorage('access_token');
        if(access_token)
        {     
            const response = await API.get(url, {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            })
            return response.data;
        }
        else
        {
            // 재로그인
            return { status: 401, data: {message: '로그인 만료', action: '/error'}};
        }
    }
    catch(err)
    {
        return { status: 401, data: {message: '로그인 만료', action: '/error'}};
    }
}

export const getStoreAdvertisementPageRequest =async (storeId: number, state: number, pNo: number, size: number, sort: string) => {
    const url = BASE_URL + `/v1/store/advertisement/${storeId}/${state}/${pNo}/${size}/${sort}`;
    API.defaults.withCredentials = true;

    try {
        const access_token = getLocalStorage('access_token');
        if(access_token)
        {     
            const response = await API.get(url, {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            })
            return response.data;
        }
        else
        {
            // 재로그인
            return { status: 401, data: {message: '로그인 만료', action: '/error'}};
        }
    }
    catch(err)
    {
        return { status: 401, data: {message: '로그인 만료', action: '/error'}};
    }
}

export const getInfluencerAdvertisementPageRequest = async (influencerId: number, state: number, pNo: number, size: number, sort: string) => {

    const url = BASE_URL + `/v1/influencer/advertisement/${influencerId}/${state}/${pNo}/${size}/${sort}`;
    API.defaults.withCredentials = true;

    try {
        const access_token = getLocalStorage('in_access_token');
        if(access_token)
        {     
            const response = await API.get(url, {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            })
            return { status: 200, data: response.data.data} 
        }
        else
        {
            // 재로그인
            return { status: 401, data: {message: '로그인 만료', action: '/error'}};
        }
    }
    catch(err)
    {
        return { status: 401, data: {message: '로그인 만료', action: '/error'}};
    }
}

export const getAdvertisementBookingPageRequest =async (advertisementId:number, pNo: number, size: number, sort: string) => {
    const url = BASE_URL + `/v1/store/advertisement/booking/${advertisementId}/${pNo}/${size}/${sort}`;
    API.defaults.withCredentials = true;

    try {
        const access_token = getLocalStorage('access_token');
        if(access_token)
        {     
            const response = await API.get(url, {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            })
            return response.data;
        }
        else
        {
            // 재로그인
            return { status: 401, data: {message: '로그인 만료', action: '/error'}};
        }
    }
    catch(err)
    {
        return { status: 401, data: {message: '로그인 만료', action: '/error'}};
    }
}

export const completeBookingRequest = async (bookingId: number) => {
    const url = BASE_URL + `/v1/booking/complete/${bookingId}`;
    API.defaults.withCredentials = true;

    try {
        const access_token = getLocalStorage('access_token');
        const data = {
        }

        if(access_token)
        {      
            const response = await API.put(url, data, {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            })

            return response.data;
        }
        else
        {
            // 재로그인
            return { status: 401, data: {message: '로그인 만료', action: '/error'}};
        }
    }
    catch(err)
    {
        return { status: 401, data: {message: '로그인 만료', action: '/error'}};
    }
} 

export const noshowBookingRequest =async (bookingId: number) => {
    const url = BASE_URL + `/v1/booking/noshow/${bookingId}`;
    API.defaults.withCredentials = true;

    try {
        const access_token = getLocalStorage('access_token');
        const data = {
        }

        if(access_token)
        {      
            const response = await API.put(url, data, {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            })

            return response.data;
        }
        else
        {
            // 재로그인
            return { status: 401, data: {message: '로그인 만료', action: '/error'}};
        }
    }
    catch(err)
    {
        return { status: 401, data: {message: '로그인 만료', action: '/error'}};
    }
}

export const confirmBookingRequest = async (bookingId: number, resrvTime: string, duration: number) => {
    const url = BASE_URL + `/v1/booking/confirm/${bookingId}/${resrvTime}/${duration}`;
    API.defaults.withCredentials = true;

    try {
        const access_token = getLocalStorage('access_token');
        const data = {
        }

        if(access_token)
        {      
            const response = await API.put(url, data, {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            })

            return response.data;
        }
        else
        {
            // 재로그인
            return { status: 401, data: {message: '로그인 만료', action: '/error'}};
        }
    }
    catch(err)
    {
        return { status: 401, data: {message: '로그인 만료', action: '/error'}};
    }
}

export const rejectBookingRequest = async (bookingId: number, message: string) => {
    const url = BASE_URL + `/v1/booking/reject/${bookingId}/${message}`;
    API.defaults.withCredentials = true;

    try {
        const access_token = getLocalStorage('access_token');
        const data = {
        }

        if(access_token)
        {      
            const response = await API.put(url, data, {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            })

            return response.data;
        }
        else
        {
            // 재로그인
            return { status: 401, data: {message: '로그인 만료', action: '/error'}};
        }
    }
    catch(err)
    {
        return { status: 401, data: {message: '로그인 만료', action: '/error'}};
    }
}

export const adminCancelRequest = async (bookingId: number, message: string) => {
    const url = BASE_URL + `/v1/booking/admincancel/${bookingId}`;
    API.defaults.withCredentials = true;

    try {
        const access_token = getLocalStorage('access_token');
        const data = {
            memo: message
        };

        if(access_token)
        {      
            const response = await API.put(url, data, {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            })

            return response.data;
        }
        else
        {
            // 재로그인
            return { status: 401, data: {message: '로그인 만료', action: '/error'}};
        }
    }
    catch(err)
    {
        return { status: 401, data: {message: '로그인 만료', action: '/error'}};
    }
}

//
export const userCancelRequest = async (bookingId: number) => {
    const url = BASE_URL + `/v1/booking/usercancel/${bookingId}`;
    API.defaults.withCredentials = true;

    try {
        const access_token = getLocalStorage('access_token');

        if(access_token)
        {      
            const response = await API.put(url, {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            })

            return response.data;
        }
        else
        {
            // 재로그인
            return { status: 401, data: {message: '로그인 만료', action: '/error'}};
        }
    }
    catch(err)
    {
        return { status: 401, data: {message: '로그인 만료', action: '/error'}};
    }
}


export const getUserBookingInfoRequest = async (connectKey:string) => {
    const url = BASE_URL + `/v1/booking/user/${connectKey}`;
    API.defaults.withCredentials = true;

    try {       
        const response = await API.get(url)
        return response.data;       
    }
    catch(err)
    {
        return { status: 401, data: {message: '알 수없는 에러', action: '/error'}};
    }   
}

// payment
export const updatePaymentRequest = async (payment:PaymentType) => {
    const url = BASE_URL + `/v1/payment`;
    API.defaults.withCredentials = true;

    try {
        const access_token = getLocalStorage('access_token');
        if(access_token)
        {
            const data = {
                ...payment
            }

            const response = await API.put(url, data, {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            })

            return response.data;
        }
        else
        {
            // 재로그인
            return { status: 401, data: {message: '로그인 만료', action: '/error'}};
        }
    }
    catch(err)
    {
        return { status: 401, data: {message: '알 수없는 에러', action: '/error'}};
    }  
}

// get payment by booking
export const getPaymentRequest = async (bookingId:number) => {
    const url = BASE_URL + `/v1/payment/${bookingId}`;
    API.defaults.withCredentials = true;


    try {
        const access_token = getLocalStorage('access_token');
        if(access_token)
        {     
            const response = await API.get(url, {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            })
            return response.data;
        }
        else
        {
            // 재로그인
            return { status: 401, data: {message: '로그인 만료', action: '/error'}};
        }
    }
    catch(err)
    {
        return { status: 401, data: {message: '로그인 만료', action: '/error'}};
    }
} 

// update User Memo
export const updateUserMemoRequest = async (customerId: number, memo: string) => {
    const url = BASE_URL + `/v1/customer/memo/${customerId}`;
    API.defaults.withCredentials = true;

    try {
        const access_token = getLocalStorage('access_token');
        if(access_token)
        {
            const data = {
                memo
            };

            const response = await API.put(url, data, {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            })

            return response.data;
        }
        else
        {
            // 재로그인
            return { status: 401, data: {message: '로그인 만료', action: '/error'}};
        }
    }
    catch(err)
    {
        return { status: 401, data: {message: '알 수없는 에러', action: '/error'}};
    }  
}

//get store stat
//
// get payment by booking
export const getStoreStatRequest = async (storeId:number, phoneNumber:string, start:string, end:string) => {
    const url = BASE_URL + `/v1/stat/${storeId}/${phoneNumber}/${start}/${end}`;
    API.defaults.withCredentials = true;
    
    try {
        const access_token = getLocalStorage('access_token');
        if(access_token)
        {     
            const response = await API.get(url, {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            })
            return response.data;
        }
        else
        {
            // 재로그인
            return { status: 401, data: {message: '로그인 만료', action: '/error'}};
        }
    }
    catch(err)
    {
        return { status: 401, data: {message: '로그인 만료', action: '/error'}};
    }

} 

// search customer
export const searchStoreCustomerRequest = async (storeId: number, start: string, end: string, pNo: number, keyword: string, favorites: boolean) => {
    //
    const url = BASE_URL + `/v1/customer/${storeId}/${start}/${end}/${pNo}/10/customer_id/${keyword}/${favorites}`;
    API.defaults.withCredentials = true;

    try {
        const access_token = getLocalStorage('access_token');
        if(access_token)
        {     
            const response = await API.get(url, {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            })
            return response.data;
        }
        else
        {
            // 재로그인
            return { status: 401, data: {message: '로그인 만료', action: '/error'}};
        }
    }
    catch(err)
    {
        return { status: 401, data: {message: '로그인 만료', action: '/error'}};
    }
}

//
export const getBookingPageByCustomerRequest = async (customerId: number, pNo: number) => {
    const url = BASE_URL + `/v1/booking/customer/${customerId}/${pNo}/10/Id`;
    API.defaults.withCredentials = true;

    try {
        const access_token = getLocalStorage('access_token');
        if(access_token)
        {     
            const response = await API.get(url, {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            })
            return response.data;
        }
        else
        {
            // 재로그인
            return { status: 401, data: {message: '로그인 만료', action: '/error'}};
        }
    }
    catch(err)
    {
        return { status: 401, data: {message: '로그인 만료', action: '/error'}};
    }
}

// update customer info 
export const updateCustomerInfo = async (customer: UpdateCustomerType) => {
    const url = BASE_URL + `/v1/customer`;
    API.defaults.withCredentials = true;

    try {
        const access_token = getLocalStorage('access_token');
        if(access_token)
        {
            const data = {
                ...customer
            };

            const response = await API.put(url, data, {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            })

            return response.data;
        }
        else
        {
            // 재로그인
            return { status: 401, data: {message: '로그인 만료', action: '/error'}};
        }
    }
    catch(err)
    {
        return { status: 401, data: {message: '알 수없는 에러', action: '/error'}};
    }  
}

// update customer photo 
export const uploadCustomerPhoto = async (file:any, customerId: number, type: string) => {
    const url = BASE_URL + `/v1/customer/photo/${customerId}/${type}`;

    try{
        const access_token = getLocalStorage('access_token');
        if(access_token)
        {
            const formData = new FormData();
            formData.append('file', file[0]);
    
            const response = await API.post(url, formData, {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            });
            return response.data;
        }
        else
        {
            return { status: 400, data: {message: '잘못된 정보입니다.', action: '/error'}};
        }
           
    }
    catch(err)
    {

        return { status: 400, data: {message: '잘못된 정보입니다.', action: '/error'}};
    }
}

// get customer info 
export const getCustomerInfoRequest = async (customerId: number) => {
    const url = BASE_URL + `/v1/customer/info/${customerId}`;
    API.defaults.withCredentials = true;

    try {
        const access_token = getLocalStorage('access_token');
        if(access_token)
        {     
            const response = await API.get(url, {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            })
            return response.data;
        }
        else
        {
            // 재로그인
            return { status: 401, data: {message: '로그인 만료', action: '/error'}};
        }
    }
    catch(err)
    {
        return { status: 401, data: {message: '로그인 만료', action: '/error'}};
    }
}

export const createCustomerRequest = async (customer: CustomerType) => {
    const url = BASE_URL + `/v1/customer`;
    API.defaults.withCredentials = true;

    try {
        const access_token = getLocalStorage('access_token');
        if(access_token)
        {
            const data = {
                ...customer
            };

            const response = await API.post(url, data, {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            })

            return response.data;
        }
        else
        {
            // 재로그인
            return { status: 401, data: {message: '로그인 만료', action: '/error'}};
        }
    }
    catch(err)
    {
        return { status: 401, data: {message: '알 수없는 에러', action: '/error'}};
    }  
}

//
export const getStoreBookingPageRequest = async (storeId:number, phoneNumber:string, start:string, end:string, pNo:number) => {
    const url = BASE_URL + `/v1/booking/customer/list/${storeId}/${phoneNumber}/${start}/${end}/${pNo}/10/Id`;
    API.defaults.withCredentials = true;

    try {
        const access_token = getLocalStorage('access_token');
        if(access_token)
        {     
            const response = await API.get(url, {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            })
            return response.data;
        }
        else
        {
            // 재로그인
            return { status: 401, data: {message: '로그인 만료', action: '/error'}};
        }
    }
    catch(err)
    {
        return { status: 401, data: {message: '로그인 만료', action: '/error'}};
    }
}

// add schedule
export const addBookingScheduleRequest = async (booking: AddBookingType, time: string) => {
    const url = BASE_URL + `/v1/booking/add/${time}`;
    API.defaults.withCredentials = true;

    try {
        const access_token = getLocalStorage('access_token');
        if(access_token)
        {
            const data = {
                ...booking
            };

            const response = await API.post(url, data, {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            })

            return response.data;
        }
        else
        {
            // 재로그인
            return { status: 401, data: {message: '로그인 만료', action: '/error'}};
        }
    }
    catch(err)
    {
        return { status: 401, data: {message: '알 수없는 에러', action: '/error'}};
    }  
} 

// get 
//
export const getUpdateBookingInfoRequest = async (bookingId: number) => {
    const url = BASE_URL + `/v1/booking/update/${bookingId}`;
    API.defaults.withCredentials = true;

    try {
        const access_token = getLocalStorage('access_token');
        if(access_token)
        {     
            const response = await API.get(url, {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            })
            return response.data;
        }
        else
        {
            // 재로그인
            return { status: 401, data: {message: '로그인 만료', action: '/error'}};
        }
    }
    catch(err)
    {
        return { status: 401, data: {message: '로그인 만료', action: '/error'}};
    }
}


// update 
export const updateBookingScheduleRequest = async (booking: UpdateBookingType) => {
    ///v1/booking/update/{time}/{bookingId}
    const url = BASE_URL + `/v1/booking/update`;
    API.defaults.withCredentials = true;

    try {
        const access_token = getLocalStorage('access_token');
        if(access_token)
        {
            const data = {
                ...booking
            };

            const response = await API.put(url, data, {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            })

            return response.data;
        }
        else
        {
            // 재로그인
            return { status: 401, data: {message: '로그인 만료', action: '/error'}};
        }
    }
    catch(err)
    {
        return { status: 401, data: {message: '알 수없는 에러', action: '/error'}};
    }   
}

// delete customer request
export const deleteCustomerRequest = async (customerId: number) => {
    const url = BASE_URL + `/v1/customer/${customerId}`;
    API.defaults.withCredentials = true;

    try {
        const access_token = getLocalStorage('access_token');
        if(access_token)
        {         

            const response = await API.delete(url, {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            })

            return response.data;
        }
        else
        {
            // 재로그인
            return { status: 401, data: {message: '로그인 만료', action: '/error'}};
        }
    }
    catch(err)
    {
        return { status: 401, data: {message: '알 수없는 에러', action: '/error'}};
    }  
} 

// get store plan
export const getStorePlanRequest = async (storeId: number) => {
    const url = BASE_URL + `/v1/plan/${storeId}`;
    API.defaults.withCredentials = true;

    try {
        const access_token = getLocalStorage('access_token');
        if(access_token)
        {     
            const response = await API.get(url, {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            })
            return response.data;
        }
        else
        {
            // 재로그인
            return { status: 401, data: {message: '로그인 만료', action: '/error'}};
        }
    }
    catch(err)
    {
        return { status: 401, data: {message: '로그인 만료', action: '/error'}};
    }
}

// get store charge info
export const getStoreChargeInfoRequest = async (storeId: number, selectedYear: number, selectedMonth: number) => {   

    const url = BASE_URL + `/v1/charge/${storeId}/${selectedYear}/${selectedMonth}`;
    API.defaults.withCredentials = true;

    try {
        const access_token = getLocalStorage('access_token');
        if(access_token)
        {     
            const response = await API.get(url, {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            })
            return response.data;
        }
        else
        {
            // 재로그인
            return { status: 401, data: {message: '로그인 만료', action: '/error'}};
        }
    }
    catch(err)
    {
        return { status: 401, data: {message: '로그인 만료', action: '/error'}};
    }
}

// get store message send page 
export const getStoreMessageSendPageRequest = async (storeId: number, selectedYear: number, selectedMonth: number, pNo: number) => {
    const url = BASE_URL + `/v1/message/${storeId}/${selectedYear}/${selectedMonth}/${pNo}/20/Id`;
    API.defaults.withCredentials = true;

    try {
        const access_token = getLocalStorage('access_token');
        if(access_token)
        {     
            const response = await API.get(url, {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            })
            return response.data;
        }
        else
        {
            // 재로그인
            return { status: 401, data: {message: '로그인 만료', action: '/error'}};
        }
    }
    catch(err)
    {
        return { status: 401, data: {message: '로그인 만료', action: '/error'}};
    }
}

// admin booking time check manual
export const getAdminBookingTimeCheckManualRequest = async (storeId: number, requestDate: string, startTime: string, duration: number) => {
    const url = BASE_URL + `/v1/booking/checkmanual/validation/${storeId}/${requestDate}/${startTime}/${duration}`;
    API.defaults.withCredentials = true;
    
    try {
        const access_token = getLocalStorage('access_token');
        if(access_token)
        {     
            const response = await API.get(url, {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            })
            return response.data;
        }
        else
        {
            // 재로그인
            return { status: 401, data: {message: '로그인 만료', action: '/error'}};
        }
    }
    catch(err)
    {
        return { status: 401, data: {message: '로그인 만료', action: '/error'}};
    }
}

// admin bookingtime check
export const getAdminBookingTimeCheckRequest = async (bookingId: number, startTime: string, duration: number) => {
    const url = BASE_URL + `/v1/booking/check/validation/${bookingId}/${startTime}/${duration}`;
    API.defaults.withCredentials = true;

    try {
        const access_token = getLocalStorage('access_token');
        if(access_token)
        {     
            const response = await API.get(url, {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            })
            return response.data;
        }
        else
        {
            // 재로그인
            return { status: 401, data: {message: '로그인 만료', action: '/error'}};
        }
    }
    catch(err)
    {
        return { status: 401, data: {message: '로그인 만료', action: '/error'}};
    }
}

//
export const checkCustomerDupRequest = async (storeId: number, phoneNumber: string) => {
    const url = BASE_URL + `/v1/customer/check/${storeId}/${phoneNumber}`;
    API.defaults.withCredentials = true;

    try {
        const access_token = getLocalStorage('access_token');
        if(access_token)
        {     
            const response = await API.get(url, {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            })
            return response.data;
        }
        else
        {
            // 재로그인
            return { status: 401, data: {message: '로그인 만료', action: '/error'}};
        }
    }
    catch(err)
    {
        return { status: 401, data: {message: '로그인 만료', action: '/error'}};
    }
}

// timeout cancel
///v1/booking/timeout/{bookingId}
export const timeoutCancelRequest = async (bookingId: number) => {
    const url = BASE_URL + `/v1/booking/timeout/${bookingId}`;
    API.defaults.withCredentials = true;

    try {
        const access_token = getLocalStorage('access_token');
        if(access_token)
        {      
            const response = await API.put(url, {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            })

            return response.data;
        }
        else
        {
            // 재로그인
            return { status: 401, data: {message: '로그인 만료', action: '/error'}};
        }
    }
    catch(err)
    {
        return { status: 401, data: {message: '로그인 만료', action: '/error'}};
    }
}

export const getMenuDetailRequest = async (menuId: number) => {
    //
    const url = BASE_URL + `/v1/menu/detail/${menuId}`;
    API.defaults.withCredentials = true;

    try {
        const access_token = getLocalStorage('access_token');
        if(access_token)
        {     
            const response = await API.get(url, {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            })
            return response.data;
        }
        else
        {
            // 재로그인
            return { status: 401, data: {message: '로그인 만료', action: '/error'}};
        }
    }
    catch(err)
    {
        return { status: 401, data: {message: '로그인 만료', action: '/error'}};
    }
}

// 
export const getDateValidationRequest = async (storeId: number, requestDate: string) => {
    //
    const url = BASE_URL + `/v1/booking/date/validation/${storeId}/${requestDate}`;
    API.defaults.withCredentials = true;

    try {
        const access_token = getLocalStorage('access_token');
        if(access_token)
        {     
            const response = await API.get(url, {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            })
            return response.data;
        }
        else
        {
            // 재로그인
            return { status: 401, data: {message: '로그인 만료', action: '/error'}};
        }
    }
    catch(err)
    {
        return { status: 401, data: {message: '로그인 만료', action: '/error'}};
    }
}

//
export const checkUpdateBookingValidationRequest = async (storeId: number, bookingId: number, requestDate: string, startTime: string, duration: number) => {
    //
    const url = BASE_URL + `/v1/booking/update/validation/${storeId}/${bookingId}/${requestDate}/${startTime}/${duration}`;
    API.defaults.withCredentials = true;

    try {
        const access_token = getLocalStorage('access_token');
        if(access_token)
        {     
            const response = await API.get(url, {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            })
            return response.data;
        }
        else
        {
            // 재로그인
            return { status: 401, data: {message: '로그인 만료', action: '/error'}};
        }
    }
    catch(err)
    {
        return { status: 401, data: {message: '로그인 만료', action: '/error'}};
    }
}

//
export const adminBookingDeleteRequest = async (bookingId: number) => {
    const url = BASE_URL + `/v1/booking/delete/${bookingId}`;
    API.defaults.withCredentials = true;

    try {
        const access_token = getLocalStorage('access_token');
        if(access_token)
        {     
            const response = await API.put(url, null, {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            })
            return response.data;
        }
        else
        {
            // 재로그인
            return { status: 401, data: {message: '로그인 만료', action: '/error'}};
        }
    }
    catch(err)
    {
        return { status: 401, data: {message: '로그인 만료', action: '/error'}};
    }
}

//
export const changeFavoriteCustomerRequest = async (customerId: number) => {
    const url = BASE_URL + `/v1/customer/favorites/change/${customerId}`;
    API.defaults.withCredentials = true;

    try {
        const access_token = getLocalStorage('access_token');
        if(access_token)
        {     
            const response = await API.put(url, null, {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            })
            return response.data;
        }
        else
        {
            // 재로그인
            return { status: 401, data: {message: '로그인 만료', action: '/error'}};
        }
    }
    catch(err)
    {
        return { status: 401, data: {message: '로그인 만료', action: '/error'}};
    }
}

// 
export const getStorePhotoUploadInfoRequest = async (storeId: number) => {
    //
    const url = BASE_URL + `/v1/store/photo/${storeId}`;
    API.defaults.withCredentials = true;

    try {
        const access_token = getLocalStorage('access_token');
        if(access_token)
        {     
            const response = await API.get(url, {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            })
            return response.data;
        }
        else
        {
            // 재로그인
            return { status: 401, data: {message: '로그인 만료', action: '/error'}};
        }
    }
    catch(err)
    {
        return { status: 401, data: {message: '로그인 만료', action: '/error'}};
    }
}

// 
export const updatePhotoUploadRequest = async (storeId: number, upload: boolean, description: string) => {
    // 
    const url = BASE_URL + `/v1/store/photo/${storeId}`;
    API.defaults.withCredentials = true;

    const payload:StorePhotoUploadType = {
        upload: upload,
        description: description
    }

    const data = {
        ...payload
    }

    try {
        const access_token = getLocalStorage('access_token');
        if(access_token)
        {          
            const response = await API.put(url, data, {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            })

            return response.data;
        }
        else
        {
            // 재로그인
            return { status: 401, data: {message: '로그인 만료', action: '/error'}};
        }
    }
    catch(err)
    {
        return { status: 401, data: {message: '로그인 만료', action: '/error'}};
    }
}

// upload photo 
//
export const uploadPhotoFileRequest = async (file:any, bookingId: number, orderNo: number, type: string, pw: string) => {
    const url = BASE_URL + `/v1/booking/upload/photo/${bookingId}/${orderNo}/${type}/${pw}`;
    try{
       
        const formData = new FormData();
        formData.append('file', file[0]);
        const response = await API.post(url, formData, {            
        });
        return response.data;
       
           
    }
    catch(err)
    {
        return { status: 400, data: {message: '잘못된 정보입니다.', action: '/error'}};
    }
}

// get booking detail
//
export const getBookingDtailRequest = async (bookingId: number) => {
    //
    const url = BASE_URL + `/v1/booking/detail/${bookingId}`;
    API.defaults.withCredentials = true;

    try {
        const access_token = getLocalStorage('access_token');
        if(access_token)
        {     
            const response = await API.get(url, {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            })
            return response.data;
        }
        else
        {
            // 재로그인
            return { status: 401, data: {message: '로그인 만료', action: '/error'}};
        }
    }
    catch(err)
    {
        return { status: 401, data: {message: '로그인 만료', action: '/error'}};
    }
}

// get daily break time
export const getDailyBreakTimeListRequest = async (storeId: number, date: string) => {
    const url = BASE_URL + `/v1/schedule/daily/break/${storeId}/${date}`;
    API.defaults.withCredentials = true;

    try {
        const access_token = getLocalStorage('access_token');
        if(access_token)
        {     
            const response = await API.get(url, {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            })
            return response.data;
        }
        else
        {
            // 재로그인
            return { status: 401, data: {message: '로그인 만료', action: '/error'}};
        }
    }
    catch(err)
    {
        return { status: 401, data: {message: '로그인 만료', action: '/error'}};
    }
}

// delete daily break time
export const deleteDailyBreakTimeRequest = async (dailyBreakTimeId: number) => {
    const url = BASE_URL + `/v1/schedule/daily/break/${dailyBreakTimeId}`;
    API.defaults.withCredentials = true;

    try {
        const access_token = getLocalStorage('access_token');
        if(access_token)
        {
             const response = await API.delete(url, {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            })

            return response.data;
        }
        else
        {
            // 재로그인
            return { status: 401, data: {message: '로그인 만료', action: '/error'}};
        }
    }
    catch(err)
    {
        return { status: 401, data: {message: '로그인 만료', action: '/error'}};
    }
}

// get monthly store booking list 
export const getStoreMonthlyBookingListRequest = async (storeId: number, year: number, month: number) => {
    const url = BASE_URL + `/v1/admin/booking/summary/${storeId}/${year}/${month}`;

    try {
        const access_token = getLocalStorage('access_token');
        if(access_token)
        {     
            const response = await API.get(url, {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            })
            return response.data;
        }
        else
        {
            // 재로그인
            return { status: 401, data: {message: '로그인 만료', action: '/error'}};
        }
    }
    catch(err)
    {
        return { status: 401, data: {message: '로그인 만료', action: '/error'}};
    }
}

// post save daily break time
export const addDailyBreakTimeRequest = async (breakTimeList: DailyBreakTimeType[], storeId: number, date: string) => {
    const url = BASE_URL + `/v1/schedule/daily/break/${storeId}/${date}`;

    try{
        const access_token = getLocalStorage('access_token');
        if(access_token)
        {            
            const data = [
                ...breakTimeList
            ]

            const response = await API.post(url, data, {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            });
            return response.data;
        }
        else
        {
            return { status: 400, data: {message: '잘못된 정보입니다.', action: '/error'}};
        }
           
    }
    catch(err)
    {

        return { status: 400, data: {message: '잘못된 정보입니다.', action: '/error'}};
    }
}


