import { useEffect, useState } from "react";
import styled from "styled-components";
import {
    TimePicker,
    TimePickerModal
} from '../modals';

const Container = styled.div`
    width: 100%;
`;

const Time = styled.div`
    width: 100%;
    height: 48px;
    background: #FFFFFF;
    border: 1px solid #AFB8C0;
    border-radius: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.5px;
    color: #7D93A6;
`;

const Icon = styled.img`
    width: 16px;
`;

interface SettingTimeProps {
    onSettingTime: (hour: number, minute: number) => void;
    time: string;
    placeHolder?: string;
    timeRange: string [];
}

export function SettingTime({
    onSettingTime,
    time,
    placeHolder,
    timeRange
}: SettingTimeProps) {
    const [ isTimeSetting, setIsTimeSetting] = useState(false);
    const [ selectedTime, setSelectedTime ] = useState<string>();

    function timeView(time: string)
    {
        return time
    }

    useEffect(() => {
        
        if(time !== '')
        {
            let tlist = time.split(':');
            if(tlist.length > 1)
            {
                setSelectedTime(tlist[0] + ":" + tlist[1]);
            }           
        }
      
    }, [])

    return (
        <Container>
            { isTimeSetting  && <TimePickerModal 
                initTime={selectedTime}
                timeRange={timeRange}
                onSelectTime={(hour, minute) => {
                    setSelectedTime(hour + ":" + minute)
                    onSettingTime(Number(hour), Number(minute))
                    setIsTimeSetting(false);
                }}
                onClose={() => {
                    setIsTimeSetting(false)
                }}
            />}
            <Time
                onClick={() => {
                    setIsTimeSetting(true);
                }}
            >{ time === '' ? placeHolder : timeView(time)}
                <Icon 
                    src={require('../../styles/assets/icon/time.png')}
                />
            </Time>
        </Container>
    );
}