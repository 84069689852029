import styled from "styled-components";

const BarContainer  = styled.div`
    width: 100%;
    height: 70px;
    background-color: #ffffff;
    color: #000000;
    position: fixed;
    bottom: 0;
    left: 0;
    display: grid;
    justify-content: space-around;
    align-items: center;
    grid-template-columns: 80px 80px 80px;
    gap: 10px;
    padding: 0px;
    border-top: 0.1px solid #BEBEBE;
`;

const Button = styled.span<{active: boolean}>`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    letter-spacing: -0.15px;
    cursor: pointer;
    color: ${(props) => props.active ? '#9C27B0' : '#7D93A6'};
    margin-top: 6px;
`;

const RowWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
`;

const Image = styled.img`
    width: 20px;
`;
interface NavBarCmProps {
    onClick: (path:number) => void;
    active: number
}

export function NavBarCm({
    onClick,
    active
}: NavBarCmProps){
    return (
        <BarContainer>
            <RowWrapper
                onClick={() => {onClick(0)}}
            >
                {
                    active === 0 ? <Image src={require('../../../styles/assets/community/home_active.png')}/>
                    : <Image src={require('../../../styles/assets/community/home_inactive.png')}/>
                }
                
                <Button active={active === 0 ? true : false}>홈</Button>                
            </RowWrapper>
            <RowWrapper
                onClick={() => {onClick(1)}}
            >
                {
                    active === 1 ? <Image src={require('../../../styles/assets/community/edit_active.png')}/>
                    : <Image src={require('../../../styles/assets/community/edit_inactive.png')}/>
                }
                
                <Button active={active === 1 ? true : false}>작성</Button>                
            </RowWrapper>
            <RowWrapper
                onClick={() => {onClick(2)}}
            >
                {
                    active === 2 ? <Image src={require('../../../styles/assets/community/profile_active.png')}/>
                    : <Image src={require('../../../styles/assets/community/profile_inactive.png')}/>
                }
                
                <Button active={active === 2 ? true : false}>프로필</Button>                
            </RowWrapper>       
        </BarContainer>
    );
}